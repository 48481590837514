import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BigTitle from "../components/title/5-bigTitle"
import SubTitle from "../components/title/6-subTitle"
import AMap from "../components/A-map"
import Brands from "../components/9-brands"
import Images from "../components/image"
import Layout from "../components/0-layout"
import Lists from "../components/8-lists"
import Points from "../components/7-points"
import Query from "../components/query"
import SEO from "../components/seo"

/**
 * @property {object} allServiceJson
 * @property {object} subTitle
 * @property {object} wareHouse
 * @property {object} webSystem
 */

const ServicePage = () => {
  const data = Query()

  const wh = useStaticQuery(graphql`
      query ServiceQuery {
          allServiceJson {
              edges {
                  node {
                      subTitle {
                          fileNameSubTitle
                          altSubTitle
                          fileNameImg
                          altImg
                          text
                      }
                      points {
                          li
                          count
                          alt
                      }
                      wareHouse {
                          b
                          p
                      }
                      webSystem {
                          b
                          p
                      }
                  }
              }
          }
      }
  `)

  return (
    <Layout sitePath={"service"} pageTitle={data.allFooterJson.edges[1].node.li}>

      <SEO title={data.site.siteMetadata.service}/>

      <BigTitle
        filename={"warehouse/big-title.jpg"}
        alt={data.site.siteMetadata.patent}
        text={
          "登録審査を受けた信頼性の高い倉庫と、安全・便利な Web 管理システムで" +
          "お客様・取次店・倉庫業者にメリットをご提供します。"
        }
      />

      <SubTitle v={wh.allServiceJson.edges[0].node.subTitle}/>

      <Points v={wh.allServiceJson.edges[0].node.points}/>

      <div className={"img"}>
        <Images filename={"warehouse/web-system.jpg"}
                alt={data.site.siteMetadata.webSystem}/>
      </div>

      <div className={"lists"}>
        <Lists t={"信頼性の高い倉庫"}
               v={wh.allServiceJson.edges[0].node.wareHouse}/>

        <Lists t={"安全・便利な Web 管理システム"}
               v={wh.allServiceJson.edges[0].node.webSystem}/>
      </div>

      <AMap/>

      <Brands/>

    </Layout>
  )
}

export default ServicePage
